import { NextRouter } from 'next/router'

/**
 * URLからChatRoomIdを取得する
 */
export const getChatRoomId = ({
  router,
  pathname,
}: {
  router: NextRouter
  pathname: string
}): string | undefined => {
  const { chatRoomId } = router.query

  if (typeof chatRoomId === 'string') {
    return chatRoomId
  }

  return pathname ? pathname.replace(/^\/|\/$/g, '') : undefined
}
