import { createTheme } from '@mui/material/styles'
import { bgColor } from './customTheme'

export const theme = createTheme({
  palette: {
    success: {
      main: '#1C7AE8',
    },
    error: {
      main: '#D32827',
    },
  },
  typography: {
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(),
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '::after': {
            content: '""',
            left: 14,
            top: 14,
            height: 15,
            width: 15,
            position: 'absolute',
            backgroundColor: bgColor.white,
            zIndex: -1,
          },
        },
      },
    },
  },
})
