import { Box, styled, Typography } from '@mui/material'
import Link from 'next/link'
import { bgColor, fontWeight, textColor } from '@/utils/themeConfigs/customTheme'

const StyledLink = styled(Link, { shouldForwardProp: (prop) => prop !== 'isCurrent' })<{
  isCurrent: boolean
}>`
  width: 100%;
  height: 50px;
  padding: 15px 16px 15px 24px;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: ${({ isCurrent }) =>
    isCurrent ? bgColor.halfTransparentWhite : 'inherit'};
`

const Item = styled(Box, { shouldForwardProp: (prop) => prop !== 'isCurrent' })<{
  isCurrent: boolean
}>`
  width: 100%;
  height: 50px;
  padding: 15px 16px 15px 24px;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: ${({ isCurrent }) =>
    isCurrent ? bgColor.halfTransparentWhite : 'inherit'};
`

const ItemText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCurrent' && prop !== 'isPrompts',
})<{ isCurrent: boolean; isPrompts: boolean }>`
  font-weight: ${({ isCurrent }) => (isCurrent ? fontWeight.bold : fontWeight.normal)};
  font-size: 0.875rem;
  line-height: 1.448;
  color: ${({ isPrompts, isCurrent }) =>
    isCurrent && isPrompts ? textColor.red : textColor.dark};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

type Props = {
  text: string
  href: string
  icon?: React.ReactElement
  isCurrent: boolean
}

export const SideMenuItem = ({ text, href, icon, isCurrent }: Props) => {
  // 管理画面のプロンプト画面のみ詳細ページがあり、その際にプロンプト一覧ボタンを押下時に一覧ページに飛べるようにするため、
  // 「/admin/prompts」が含まれる場合はisCurrentでもリンクにする
  if (isCurrent && !href.includes('/admin/prompts')) {
    return (
      <Item isCurrent={isCurrent}>
        {icon}
        <ItemText
          isCurrent={isCurrent}
          isPrompts={href.includes('prompts') && !href.includes('admin')}
        >
          {text}
        </ItemText>
      </Item>
    )
  }

  return (
    <StyledLink href={href} isCurrent={isCurrent}>
      {icon}
      <ItemText
        isCurrent={isCurrent}
        isPrompts={href.includes('prompts') && !href.includes('admin')}
      >
        {text}
      </ItemText>
    </StyledLink>
  )
}
