import { z } from 'zod'

export const PromptSchema = z.object({
  prompt_name: z
    .string()
    .min(1, { message: '必須項目です' })
    .max(45, { message: '45文字以内で入力してください' })
    .refine((val) => val.trim().length !== 0, {
      message: 'スペースだけの登録はできません',
    }),
  system_prompt: z
    .string()
    .min(1, { message: '必須項目です' })
    .max(3000, { message: '3,000文字以内で入力してください' })
    .refine((val) => val.trim().length !== 0, {
      message: 'スペースだけの登録はできません',
    }),
  public_status: z.string().min(1, { message: '必須項目です' }),
  temperature: z
    .number()
    .min(0.0, { message: '0.0以上2.0以下の値を選択してください' })
    .max(2.0, { message: '0.0以上2.0以下の値を選択してください' }),
  template: z
    .string()
    .max(500, { message: '500文字以内で入力してください' })
    .refine((val) => val.length === 0 || val.trim().length !== 0, {
      message: 'スペースだけの登録はできません',
    })
    .nullable(),
  initial_message: z
    .string()
    .max(500, { message: '500文字以内で入力してください' })
    .refine((val) => val.length === 0 || val.trim().length !== 0, {
      message: 'スペースだけの登録はできません',
    })
    .nullable(),
  prompt_group_id: z.string().min(1, { message: '必須項目です' }),
  prompt_genre_id: z.string().min(1, { message: '必須項目です' }),
})

export const BoxNameSchema = z.object({
  boxName: z
    .string()
    .max(16, { message: '16文字以内で入力してください' })
    .refine((val) => val.length === 0 || val.trim().length !== 0, {
      message: 'スペースだけの登録はできません',
    }),
})

export const AdminPromptSchema = z.object({
  prompt_name: z
    .string()
    .min(1, { message: '必須項目です' })
    .max(45, { message: '45文字以内で入力してください' })
    .refine((val) => val.trim().length !== 0, {
      message: 'スペースだけの登録はできません',
    }),
  system_prompt: z
    .string()
    .min(1, { message: '必須項目です' })
    .max(1000, { message: '3,000文字以内で入力してください' })
    .refine((val) => val.trim().length !== 0, {
      message: 'スペースだけの登録はできません',
    }),
  public_status: z.string().min(1, { message: '必須項目です' }),
  temperature: z
    .number()
    .min(0.0, { message: '0.0以上2.0以下の値を選択してください' })
    .max(2.0, { message: '0.0以上2.0以下の値を選択してください' }),
  template: z
    .string()
    .max(50, { message: '500文字以内で入力してください' })
    .refine((val) => val.length === 0 || val.trim().length !== 0, {
      message: 'スペースだけの登録はできません',
    })
    .nullable(),
  initial_message: z
    .string()
    .max(50, { message: '500文字以内で入力してください' })
    .refine((val) => val.length === 0 || val.trim().length !== 0, {
      message: 'スペースだけの登録はできません',
    })
    .nullable(),
  prompt_group_id: z.string().min(1, { message: '必須項目です' }),
  prompt_genre_id: z.string().min(1, { message: '必須項目です' }),
})
