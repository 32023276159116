import { useState } from 'react'
import styled from '@emotion/styled'
import { Box, BoxProps, Button, Typography } from '@mui/material'

import { CopyIcon } from '@/components/icon/CopyIcon'
import { ThumbsUpIcon } from '@/components/icon/ThumbsUpIcon'
import { ThumbsDownIcon } from '@/components/icon/ThumbsDownIcon'
import {
  accentColor,
  bgColor,
  fontSize,
  textColor,
} from '@/utils/themeConfigs/customTheme'
import { useTextCopy } from '@/utils/hooks/useTextCopy'
import { FeedbackModal } from '@/features/user/chat/components/ui/feedback/FeedbackModal'
import { useDisclosure } from '@/utils/hooks/useDisclosure'

import { Markdown } from './Markdown'

const Container = styled(Box, {
  shouldForwardProp: (props) => props !== 'role',
})<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ role }) => (role === 'user' ? 'end' : 'start')};
`

const Bubble = styled(Box, {
  shouldForwardProp: (props) => props !== 'role' && props !== 'isHistory',
})<BubbleProps>`
  max-width: 532px;
  width: 100%;
  border-radius: ${({ role }) => (role === 'user' ? '14px 14px 0px 14px;' : '14px 14px 14px 0px;')}
  padding: 8px 14px 8px 14px;
  background-color: ${({ role, isHistory }) =>
    role === 'user'
      ? isHistory
        ? accentColor.lightGray
        : accentColor.lightRed
      : bgColor.lightestGray};
  font-weight: 400;
  font-size: ${fontSize.xs};
  line-height: 1.448;
  color: ${textColor.dark};
`

const CopyButtonText = styled(Typography)`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 2.8;
  color: ${textColor.mediumGray};
`

const DatetimeText = styled(Typography)`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 2.8;
  color: ${textColor.mediumGray};
`

const UserBubbleText = styled(Typography)`
  font-size: ${fontSize.xs};
  white-space: pre-wrap;
`

const Reference = styled(Typography)`
  font-size: ${fontSize.xs};
  color: ${textColor.mediumGray};
  margin: 8px 0 0;
`

const ActionBtnContainer = styled(Box)`
  display: flex;
`

const FeedbackButton = styled(Button)`
  min-width: 0;
`

export type Role = 'assistant' | 'user'

export type Chat = {
  chatLogId?: string
  feedbackId?: string | null
  message: string
  role: Role
  createdAt?: string
  cannotCopy?: boolean
  image?: {
    src: string
    text: string
    name: string
  }
  fileName?: string
}

type Props = {
  chat: Chat
  isHistory: boolean
}

type ContainerProps = BoxProps & {
  role: Role
}

type BubbleProps = BoxProps & {
  role: Role
  isHistory: boolean
}

export const ChatBubble = ({ chat, isHistory }: Props) => {
  const { copyToClipboard } = useTextCopy()
  const [evaluation, setEvaluation] = useState<number>(2)
  const modal = useDisclosure()

  const handleCopyToClipboard = () => {
    return void copyToClipboard(chat.message)
  }

  const handleGoodModalOpen = () => {
    setEvaluation(1)
    modal.handleOpen()
  }

  const handleBadModalOpen = () => {
    setEvaluation(2)
    modal.handleOpen()
  }

  const addTmpFeedbackId = () => {
    chat.feedbackId = 'complete_send_feedback'
  }

  return (
    <Container role={chat.role}>
      <Bubble role={chat.role} isHistory={isHistory}>
        {chat.role === 'assistant' ? (
          <Markdown text={chat.message} />
        ) : (
          <UserBubbleText>{chat.message}</UserBubbleText>
        )}
        {chat.fileName && (
          <Reference>
            この回答は{chat.fileName}の情報を元に回答が生成されています。
          </Reference>
        )}
      </Bubble>
      {chat.role === 'assistant' && !chat.cannotCopy && (
        <ActionBtnContainer>
          <Button startIcon={<CopyIcon />}>
            <CopyButtonText onClick={handleCopyToClipboard}>コピー</CopyButtonText>
          </Button>
          {chat.chatLogId && !chat.feedbackId && (
            <>
              <FeedbackButton onClick={handleGoodModalOpen}>
                <ThumbsUpIcon />
              </FeedbackButton>
              <FeedbackButton onClick={handleBadModalOpen}>
                <ThumbsDownIcon />
              </FeedbackButton>
              <FeedbackModal
                modal={modal}
                evaluation={evaluation}
                chatLogId={chat.chatLogId}
                onSuccess={addTmpFeedbackId}
              />
            </>
          )}
        </ActionBtnContainer>
      )}
      {chat.createdAt && <DatetimeText>{chat.createdAt}</DatetimeText>}
    </Container>
  )
}
