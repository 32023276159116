import { useCallback, useEffect, useRef, useState } from 'react'

import { HandleSendChatType } from '../components/page/Chat'
import { useSpeechSynthesis } from './useSpeechSynthesis'

type Props = {
  pathname: string
  handleSendChat: HandleSendChatType
  handleChangeChatInput: (value: string) => void
  chatInputValue: string
}

export const useSpeechRecognition = ({
  pathname,
  handleSendChat,
  handleChangeChatInput,
  chatInputValue,
}: Props) => {
  const speechRecognitionRef = useRef<SpeechRecognition>()
  const isNewChat = useRef(true)
  const { cancel, onend, handleReadText, isSpeaking } = useSpeechSynthesis()
  const [isRecording, setIsRecording] = useState(false)
  const transcript = useRef('')

  /**
   * 音声認識が終わった時に動く処理
   */
  const handleRecognitionResult = useCallback(
    (event: SpeechRecognitionEvent) => {
      // 認識されたテキストを取得しStateへ保存
      const results = event.results
      let result = ''
      for (let i = event.resultIndex; i < results.length; i++) {
        result += results[i][0].transcript
      }
      handleChangeChatInput(result)
      transcript.current = result

      // 短文の場合、state更新が追いつかず送信されない可能性があるためtranscriptで管理する
      if (!results[0].isFinal || !transcript) return
      setIsRecording(false)

      onend.current = () => {
        speechRecognitionRef.current?.start()
        setIsRecording(true)
      }

      // チャット送信
      handleSendChat({
        isVoiceInput: true,
        handleReadText,
        message: transcript.current,
      })
    },
    [handleSendChat, chatInputValue, handleChangeChatInput],
  )

  /**
   * 音声認識がストップした時に動く処理
   */
  const handleRecognitionEnd = useCallback(() => {
    setIsRecording(false)
    transcript.current = ''
  }, [])

  useEffect(() => {
    if (!speechRecognitionRef.current) {
      // クロスブラウザ対応のため、SpeechRecognition オブジェクトを取得
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

      // Speech Recognition API のインスタンスを生成
      const recognition = new SpeechRecognition()
      // 認識中の暫定結果をonresultで取得できるようにする
      recognition.interimResults = true
      // Edgeがlang="ja"だと日本語だと認識しなくなったので追記
      recognition.lang = 'ja-JP'
      speechRecognitionRef.current = recognition
    }

    speechRecognitionRef.current.onresult = handleRecognitionResult
    speechRecognitionRef.current.onend = handleRecognitionEnd

    return () => {
      speechRecognitionRef.current?.removeEventListener('result', handleRecognitionResult)
      speechRecognitionRef.current?.removeEventListener('end', handleRecognitionEnd)
    }
  }, [handleRecognitionResult, handleRecognitionEnd])

  // 録音を処理する関数
  const startRecording = useCallback(() => {
    // 録音を開始します。
    speechRecognitionRef.current?.start()

    setIsRecording(true)
  }, [speechRecognitionRef])

  const stopRecording = () => {
    speechRecognitionRef.current?.stop()
  }

  // コンポーネントがアンマウントされたら録音を止める
  useEffect(() => {
    return () => {
      if (speechRecognitionRef.current) {
        speechRecognitionRef.current.abort()
      }
    }
  }, [])

  useEffect(() => {
    stopRecording()
    // 新規チャットからの画面遷移時には読み上げを中止しない
    if (!isNewChat.current) cancel()
    isNewChat.current = pathname === '/'
  }, [pathname])

  return {
    isSpeaking,
    isRecording,
    startRecording,
    stopRecording,
  }
}
