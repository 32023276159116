export const FavoritePromptICon = ({ strokeColor }: { strokeColor?: string }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3748_8637)'>
        <path
          d='M0.833984 5.72287C0.833984 4.0116 0.833984 3.15597 1.16702 2.50235C1.45997 1.92741 1.92741 1.45997 2.50235 1.16702C3.15597 0.833984 4.0116 0.833984 5.72287 0.833984H14.2784C15.9897 0.833984 16.8453 0.833984 17.499 1.16702C18.0739 1.45997 18.5413 1.92741 18.8343 2.50235C19.1673 3.15597 19.1673 4.0116 19.1673 5.72287V14.2784C19.1673 15.9897 19.1673 16.8453 18.8343 17.499C18.5413 18.0739 18.0739 18.5413 17.499 18.8343C16.8453 19.1673 15.9897 19.1673 14.2784 19.1673H5.72287C4.0116 19.1673 3.15597 19.1673 2.50235 18.8343C1.92741 18.5413 1.45997 18.0739 1.16702 17.499C0.833984 16.8453 0.833984 15.9897 0.833984 14.2784V5.72287Z'
          stroke={strokeColor ?? '#333333'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.99716 7.01426C8.97897 5.82391 7.28108 5.50371 6.00536 6.59371C4.72964 7.68372 4.55003 9.50615 5.55186 10.7953C6.19354 11.621 7.83689 13.1522 8.93552 14.1464C9.30061 14.4768 9.48315 14.642 9.7018 14.7082C9.88976 14.7652 10.1046 14.7652 10.2925 14.7082C10.5112 14.642 10.6937 14.4768 11.0588 14.1464C12.1574 13.1522 13.8008 11.621 14.4425 10.7953C15.4443 9.50615 15.2866 7.67225 13.989 6.59371C12.6913 5.51518 11.0154 5.82391 9.99716 7.01426Z'
          stroke={strokeColor ?? '#333333'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3748_8637'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
