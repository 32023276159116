import { IconButton, InputAdornment, styled } from '@mui/material'
import { BasicInputText, InputTextProps } from './BasicInputText'
import { SearchLargeIcon } from '@/components/icon/SearchLargeIcon'
import { ClearIcon } from '@mui/x-date-pickers/icons'

const CustomInputText = styled(BasicInputText, {
  shouldForwardProp: (prop) => prop !== 'isSearchItem',
})<{ isSearchItem: boolean }>`
  .MuiInputBase-input {
    padding: ${({ isSearchItem }) => (isSearchItem ? '10px 12px 10px 0' : '10px 12px')};
  }
`

type Props = {
  isSearchItem?: boolean
  clearable?: boolean
  handleClear?: () => void
} & InputTextProps

export const BasicInputSearchText = (props: Props) => {
  const {
    isSearchItem = false,
    clearable = false,
    handleClear,
    ...customTextFieldProps
  } = props
  return (
    <CustomInputText
      isSearchItem={isSearchItem}
      {...customTextFieldProps}
      InputProps={{
        startAdornment: isSearchItem ? (
          <InputAdornment position='start'>
            <SearchLargeIcon />
          </InputAdornment>
        ) : null,
        endAdornment:
          clearable && handleClear ? (
            <IconButton onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          ) : null,
      }}
    />
  )
}
