import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { useApiClient } from '@/utils/hooks/useApiClient'
import { API } from '@/utils/apis/endpoints'
import { MutationKeys } from '@/utils/apis/api-keys'
import { LlmModels } from '@/utils/types/common'

import { useChatEndpoint } from './useChatEndpoint'
import { useAuthContext } from '@/context/AuthContext'

export const useFetchLlmModels = (token: string) => {
  const { apiClient } = useApiClient()
  const { controller } = useAuthContext()
  const { canConnectToApiEndpoint } = useChatEndpoint()

  const client = apiClient(token)
  const queryInfo = useQuery({
    queryKey: MutationKeys.llmModels,
    queryFn: () =>
      client
        .get<LlmModels>(API.chatModels, { signal: controller?.signal })
        .then((res) => res.data),
    enabled: !!token,
  })

  return {
    ...queryInfo,
    data: useMemo(() => {
      const llmModels = queryInfo.data ?? []

      // APIのURLが分からないものは除外する
      const filteredLlmModels = llmModels.filter((llmModel) =>
        canConnectToApiEndpoint(llmModel.group),
      )

      return filteredLlmModels
    }, [canConnectToApiEndpoint, queryInfo.data]),
  }
}
