export const DownloadIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M15.5 15.5H0.5M13 7.16667L8 12.1667M8 12.1667L3 7.16667M8 12.1667V0.5'
        stroke='#8C0026'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
