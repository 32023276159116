import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material'

import { mediaQuery } from '@/utils/helpers/breakpoint'
import { fontSize, fontWeight, textColor } from '@/utils/themeConfigs/customTheme'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { CreatePromptIcon } from '@/components/icon/CreatePromptIcon'

const Container = styled(Button)`
  .MuiButton-startIcon {
    ${mediaQuery('smallPc')} {
      margin-right: 4px;
      margin-left: 0;
    }
  }
  ${mediaQuery('smallPc')} {
    padding: 0;
  }
  ${mediaQuery('tab')} {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
`

const Text = styled(Typography)`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.sm};
  color: ${textColor.red};
  ${mediaQuery('smallPc')} {
    font-size: ${fontSize.xs};
  }
`

type Props = {
  onClick: () => void
}

export const CreatePromptButton = ({ onClick }: Props) => {
  const isMobileOrTablet = useIsMobileOrTablet()

  return (
    <Container startIcon={<CreatePromptIcon />} onClick={onClick}>
      {isMobileOrTablet !== undefined && !isMobileOrTablet && (
        <Text>新規プロンプト追加</Text>
      )}
    </Container>
  )
}
