export const FeedbackIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
    >
      <path
        d='M5.25 7.75H5.25833M9 7.75H9.00833M12.75 7.75H12.7583M7.25 15L8.46667 16.6222C8.6476 16.8635 8.73807 16.9841 8.84897 17.0272C8.94611 17.065 9.05389 17.065 9.15103 17.0272C9.26193 16.9841 9.3524 16.8635 9.53333 16.6222L10.75 15C10.9943 14.6743 11.1164 14.5114 11.2654 14.3871C11.4641 14.2213 11.6986 14.104 11.9504 14.0446C12.1393 14 12.3429 14 12.75 14C13.9149 14 14.4973 14 14.9567 13.8097C15.5693 13.556 16.056 13.0693 16.3097 12.4567C16.5 11.9973 16.5 11.4149 16.5 10.25V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V10.25C1.5 11.4149 1.5 11.9973 1.6903 12.4567C1.94404 13.0693 2.43072 13.556 3.04329 13.8097C3.50272 14 4.08515 14 5.25 14C5.65715 14 5.86072 14 6.04959 14.0446C6.30141 14.104 6.53593 14.2213 6.73458 14.3871C6.88357 14.5114 7.00571 14.6743 7.25 15ZM5.66667 7.75C5.66667 7.98012 5.48012 8.16667 5.25 8.16667C5.01988 8.16667 4.83333 7.98012 4.83333 7.75C4.83333 7.51988 5.01988 7.33333 5.25 7.33333C5.48012 7.33333 5.66667 7.51988 5.66667 7.75ZM9.41667 7.75C9.41667 7.98012 9.23012 8.16667 9 8.16667C8.76988 8.16667 8.58333 7.98012 8.58333 7.75C8.58333 7.51988 8.76988 7.33333 9 7.33333C9.23012 7.33333 9.41667 7.51988 9.41667 7.75ZM13.1667 7.75C13.1667 7.98012 12.9801 8.16667 12.75 8.16667C12.5199 8.16667 12.3333 7.98012 12.3333 7.75C12.3333 7.51988 12.5199 7.33333 12.75 7.33333C12.9801 7.33333 13.1667 7.51988 13.1667 7.75Z'
        stroke='#304386'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
