import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { MutationKeys, QueryKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'
import { useAuthContext } from '@/context/AuthContext'

import { Boxes, Prompts } from '../types'

export const useBox = () => {
  const { apiClient } = useApiClient()
  const queryClient = useQueryClient()

  const { mutate: createBox } = useMutation({
    mutationKey: MutationKeys.newBox,
    mutationFn: async ({ token, boxName }: { token: string; boxName: string }) =>
      await apiClient(token).post(API.newBox(boxName)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QueryKeys.box })
    },
  })

  const { mutate: deleteBox } = useMutation({
    mutationKey: MutationKeys.deleteBox,
    mutationFn: async ({ token, id }: { token: string; id: string }) =>
      await apiClient(token).delete(API.deleteBox(id)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QueryKeys.box })
    },
  })

  const { mutate: changeBox } = useMutation({
    mutationKey: MutationKeys.changeBox,
    mutationFn: async ({
      token,
      id,
      boxes,
    }: {
      token: string
      id: string
      boxes: string[]
    }) => await apiClient(token).post(API.changeBox(id), boxes),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: QueryKeys.promptsInBox,
      })
    },
  })

  return {
    createBox,
    deleteBox,
    changeBox,
  }
}

export const useFetchBoxes = (token: string, search_word?: string) => {
  const { apiClient } = useApiClient()
  const { controller } = useAuthContext()

  return useQuery({
    queryKey: QueryKeys.box,
    queryFn: () =>
      apiClient(token)
        .get<Boxes>(API.box(search_word), { signal: controller?.signal })
        .then((res) => res.data),
    enabled: !!token,
  })
}

export const useGetContainingBox = (token: string, id: string) => {
  const { apiClient } = useApiClient()
  const { controller } = useAuthContext()

  return useQuery({
    queryKey: QueryKeys.containingBox,
    queryFn: () =>
      apiClient(token)
        .get<Boxes>(API.containingBox(id), { signal: controller?.signal })
        .then((res) => res.data),
    enabled: !!token && !!id,
  })
}

export const useFetchPromptsInBox = ({
  token,
  id,
  query,
}: {
  token: string
  id: string
  query: string
}) => {
  const { apiClient } = useApiClient()
  const { controller } = useAuthContext()

  return useQuery({
    queryKey: [...QueryKeys.promptsInBox, id, query],
    queryFn: () =>
      apiClient(token)
        .get<Prompts>(API.promptsInBox(id, query), { signal: controller?.signal })
        .then((res) => res.data),
    enabled: !!id && !!token && !!query,
  })
}
