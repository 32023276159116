const color = {
  background: {
    white: '#FFFFFF',
    halfTransparentWhite: '#FFFFFF80',
    lightestGray: '#F8F8F8',
    lightGray: '#CECECE',
    red: '#841818',
    lightRed: '#F8EDEC',
    lightPink: ' #FEF6EE;',
    lightGreen: '#E7F7EC',
    lightBlue: '#D4E0F0',
    mediumGray: '#A1A1A1',
    dark: '#333333',
    lightYellowGreen: '#E7F0C6',
  },
  text: {
    //文字色
    white: '#FFFFFF',
    dark: '#333333',
    lightGray: '#CECECE',
    mediumGray: '#A1A1A1',
    gray: '#777777',
    red: '#841818',
  },
  outline: {
    //境界線とか枠線
    white: '#FFFFFF',
    lightestGray: '#F8F8F8',
    lightGray: '#CECECE',
    mediumGray: '#A1A1A1',
    gray: '#777777',
    dark: '#333333',
    black: '#00000005',
    red: '#841818',
    blue: '#304386',
  },
  accent: {
    //アクセントカラー
    red: '#841818',
    lightRed: '#FAEFEF',
    primaryRed: '#8C0026',
    blue: '#67A2BB',
    lightGray: '#EAECF2',
  },
  functional: {
    //機能色
    green: '#74C791',
    lightGray: '#CECECE',
    lightBeige: '#FAF1E8',
    red: '#EB5A46',
    blue: '#579BEB',
    lightPink: '#F1DADA',
    translucentWhite: '#FFFFFF33',
  },
  main: {
    user: '#841818',
    admin: '#304386',
  },
}

const font = {
  size: {
    xxxs: '10px',
    xxs: '12px',
    xs: '14px',
    sm: '16px',
    lg: '20px',
    xl: '24px',
    xxl: '28px',
    xxxl: '32px',
  },
  weight: {
    light: '300',
    normal: '400',
    bold: '700',
  },
}

// color
export const bgColor = color.background
export const textColor = color.text
export const outlineColor = color.outline
export const accentColor = color.accent
export const functionalColor = color.functional
export const mainColor = color.main

// font
export const fontSize = font.size
export const fontWeight = font.weight
