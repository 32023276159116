import { useCallback, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { useParams, usePathname, useRouter } from 'next/navigation'

import { Header } from '@/components/layout/Header/Header'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { useAuthContext } from '@/context/AuthContext'
import { Sidebar } from '@/features/user/chat/components/ui/sidebar/Sidebar'
import { PromptSideMenu } from '@/features/user/prompt/components/uis/PromptsSideMenu'
import { ROUTE_PATH } from '@/utils/constants/route'
import { NewChatButton } from '@/features/user/chat/components/ui/chat/NewChatButton'
import { CreatePromptButton } from '@/features/user/prompt/components/uis/prompt/CreatePromptButton'
import { useDisclosure } from '@/utils/hooks/useDisclosure'

import { SideMenu } from '../SideMenu/SideMenu'
import { NavigationBar } from '../NavigationBar/NavigationBar'
import { AdminSideMenu } from '../SideMenu/AdminSideMenu'

const Container = styled(Box)`
  display: flex;
  height: 100svh;
  ${mediaQuery('tab')} {
    flex-direction: column;
    padding-left: 0;
  }
`

const SideMenuSpacer = styled(Box)`
  padding: 32px 0 32px 0;
`

const Content = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`
const Wrap = styled(Box)`
  display: flex;
`

const ContentHeader = styled(Box)`
  flex-shrink: 0;
  width: 100%;
  background-color: #fff;
  height: 72px;
  ${mediaQuery('tab')} {
    position: fixed;
    z-index: 999;
  }
`

const ContentMain = styled(Box)`
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
  ${mediaQuery('tab')} {
    padding: 0 16px;
    margin: 72px 0 64px;
  }
`

type Props = {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => {
  const isMobileOrTablet = useIsMobileOrTablet()
  const { isOpen: isMenuOpen, handleOpen, handleClose } = useDisclosure()

  const router = useRouter()
  const pathName = usePathname()
  const params = useParams()
  const isManagementPortal = pathName.includes(ROUTE_PATH.admin.root.path)
  const { user, isAdmin, isStudent } = useAuthContext()
  const token = user?.token ?? ''
  const userId = user?.id ?? ''

  /**
   * メインコンテンツ：タイトル
   */
  const headerTitle = useMemo(() => {
    if (pathName.includes(ROUTE_PATH.information.path)) {
      return ROUTE_PATH.information.title
    }

    if (
      pathName.includes(ROUTE_PATH.admin.students.path) ||
      pathName.includes(ROUTE_PATH.admin.teachers.path)
    ) {
      return ROUTE_PATH.admin.students.title
    }

    if (pathName.includes(ROUTE_PATH.admin.chatRooms.path)) {
      if (params['id']) return ROUTE_PATH.admin.chatRoom.title
      return ROUTE_PATH.admin.chatRooms.title
    }

    if (pathName.includes(ROUTE_PATH.admin.feedback.path)) {
      return ROUTE_PATH.admin.feedback.title
    }

    if (pathName.includes(ROUTE_PATH.admin.group.path)) {
      return ROUTE_PATH.admin.group.title
    }

    if (pathName.includes(ROUTE_PATH.admin.policy.path)) {
      return ROUTE_PATH.admin.policy.title
    }

    if (pathName.includes(ROUTE_PATH.prompts.path)) {
      if (pathName.includes(ROUTE_PATH.myPrompts.path)) {
        return ROUTE_PATH.myPrompts.title
      }

      if (pathName.includes(ROUTE_PATH.favoritePrompts.path)) {
        return ROUTE_PATH.favoritePrompts.title
      }

      if (pathName.includes(ROUTE_PATH.createPrompts.path)) {
        return ROUTE_PATH.createPrompts.title
      }

      if (pathName.includes(ROUTE_PATH.boxes.path)) {
        return ROUTE_PATH.boxes.title
      }

      if (params['id']) {
        return ROUTE_PATH.promptDetail.title
      }

      return ROUTE_PATH.prompts.title
    }

    return ROUTE_PATH.chat.title
  }, [pathName])

  /**
   * サイドバー
   */
  const sidebarMemo = useMemo(() => {
    if (pathName.includes(ROUTE_PATH.information.path)) return
    if (pathName.includes(ROUTE_PATH.admin.root.path)) return <AdminSideMenu />
    if (
      pathName.includes(ROUTE_PATH.prompts.path) &&
      !pathName.includes(ROUTE_PATH.admin.root.path)
    ) {
      return (
        <PromptSideMenu
          token={token}
          userId={userId}
          isStudent={isStudent}
          isAdmin={isAdmin}
        />
      )
    }

    return <Sidebar token={token} />
  }, [pathName, token, userId, isStudent, isAdmin])

  const handleNewChatClick = useCallback(() => {
    router.push(ROUTE_PATH.chat.path)
  }, [])

  const handleCreatePromptClick = useCallback(() => {
    router.push(ROUTE_PATH.createPrompts.path)
  }, [])

  /**
   * SP版画面右上アクションボタン
   */
  const mobileButtonMemo = useMemo(() => {
    if (
      pathName.includes(ROUTE_PATH.information.path) ||
      pathName.includes(ROUTE_PATH.admin.root.path) ||
      pathName.includes(ROUTE_PATH.createPrompts.path)
    ) {
      return
    }

    if (
      pathName.includes(ROUTE_PATH.prompts.path) &&
      !pathName.includes(ROUTE_PATH.admin.root.path)
    ) {
      if (params['id'] && !pathName.includes(ROUTE_PATH.boxes.path)) return
      return <CreatePromptButton onClick={handleCreatePromptClick} />
    }

    return <NewChatButton onClick={handleNewChatClick} />
  }, [pathName])

  // 画面遷移時にSP版ナビゲーションを閉じる
  useEffect(() => {
    if (isMobileOrTablet) handleClose()
  }, [pathName])

  return (
    isMobileOrTablet !== undefined && (
      <Container>
        <Wrap>
          <NavigationBar isAdmin={isAdmin} isManagementPortal={isManagementPortal} />
          {sidebarMemo && !isMobileOrTablet && (
            <>
              <SideMenu>{sidebarMemo}</SideMenu>
              <SideMenuSpacer />
            </>
          )}
        </Wrap>

        <Content>
          <ContentHeader component='header'>
            <Header
              title={headerTitle}
              mobileButton={mobileButtonMemo}
              mobileMenu={
                sidebarMemo && {
                  content: sidebarMemo,
                  isMenuOpen,
                  openMenu: handleOpen,
                  closeMenu: handleClose,
                }
              }
            />
          </ContentHeader>
          <ContentMain component='main'>{children}</ContentMain>
        </Content>
      </Container>
    )
  )
}
