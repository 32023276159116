export const UserIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3334 17.5V15.8333C18.3334 14.2801 17.2711 12.9751 15.8334 12.605M12.9167 2.7423C14.1383 3.23679 15.0001 4.43443 15.0001 5.83333C15.0001 7.23224 14.1383 8.42988 12.9167 8.92437M14.1667 17.5C14.1667 15.9469 14.1667 15.1703 13.913 14.5577C13.5747 13.741 12.9258 13.092 12.109 12.7537C11.4965 12.5 10.7199 12.5 9.16675 12.5H6.66675C5.11361 12.5 4.33704 12.5 3.72447 12.7537C2.90771 13.092 2.2588 13.741 1.92048 14.5577C1.66675 15.1703 1.66675 15.9469 1.66675 17.5M11.2501 5.83333C11.2501 7.67428 9.7577 9.16667 7.91675 9.16667C6.0758 9.16667 4.58341 7.67428 4.58341 5.83333C4.58341 3.99238 6.0758 2.5 7.91675 2.5C9.7577 2.5 11.2501 3.99238 11.2501 5.83333Z'
        stroke='#304386'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
