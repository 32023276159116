import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { BasicButton } from '@/components/uis/Button/BasicButton'
import { RhfInputText } from '@/components/uis/Input/RhfInputText'
import { BoxCreateProps } from '../../../types'

const Container = styled(Box)`
  display: flex;
  gap: 8px;
  padding: 0px 24px 12px;
`

export const BoxCreate = ({
  control,
  handleSubmit,
  handleCreateBox,
  hasValue,
}: BoxCreateProps) => {
  return (
    <Container
      component='form'
      onSubmit={(event) => void handleSubmit(handleCreateBox)(event)}
    >
      <RhfInputText
        placeholder='ボックス名'
        control={control}
        name='boxName'
        autoComplete='off'
      />
      <BasicButton
        label='作成'
        radius='10'
        width='56px'
        height='40px'
        disabled={!hasValue}
        type='submit'
      />
    </Container>
  )
}
