import { createContext, useContext, useRef } from 'react'

type ExpandedProviderProps = {
  children: React.ReactNode
}

type ExpandedContextProps = {
  expanded: { [key: string]: string | false }
}

const ExpandedContext = createContext<ExpandedContextProps>({
  expanded: {},
})

export const useExpandedContext = () => useContext(ExpandedContext)

export const ExpandedProvider = ({ children }: ExpandedProviderProps) => {
  const manager = useRef({ expanded: {} }).current

  const { expanded } = manager
  return (
    <ExpandedContext.Provider value={{ expanded }}>{children}</ExpandedContext.Provider>
  )
}
