import { useRouter } from 'next/navigation'
import { createContext, useCallback, useContext, useState } from 'react'
import { UesDisclosure, useDisclosure } from '@/utils/hooks/useDisclosure'
import { Info } from '@/utils/hooks/useAuth'

type InfoProviderProps = {
  children: React.ReactNode
}

type InfoContextProps = {
  modal: UesDisclosure | null
  infoSummary: Info | null
  handleModalClose: () => void
  pageTransition: () => void
}

type SetInfoContextProps = { setInfoSummary: (info: Info | null) => void }

const InfoContext = createContext<InfoContextProps>({
  modal: null,
  infoSummary: null,
  handleModalClose: () => {},
  pageTransition: () => {},
})

const SetInfoContext = createContext<SetInfoContextProps>({
  setInfoSummary: () => {},
})

export const useInfoContext = () => useContext(InfoContext)
export const useSetInfoContext = () => useContext(SetInfoContext)

export const InfoProvider = ({ children }: InfoProviderProps) => {
  const router = useRouter()
  const modal = useDisclosure()
  const [infoSummary, setInfoSummary] = useState<Info | null>(null)

  const handleModalClose = useCallback(() => {
    modal.handleClose()
    localStorage.setItem('INFO-UNREAD', '1')
  }, [modal])

  const pageTransition = useCallback(() => {
    router.push('/information')
    handleModalClose()
  }, [handleModalClose, router])

  return (
    modal && (
      <InfoContext.Provider
        value={{
          modal,
          infoSummary,
          handleModalClose,
          pageTransition,
        }}
      >
        <SetInfoContext.Provider value={{ setInfoSummary }}>
          {children}
        </SetInfoContext.Provider>
      </InfoContext.Provider>
    )
  )
}
