export const BoxIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.0827 6.66667V13.5C17.0827 14.9001 17.0827 15.6002 16.8102 16.135C16.5705 16.6054 16.1881 16.9878 15.7177 17.2275C15.1829 17.5 14.4828 17.5 13.0827 17.5H6.91602C5.51588 17.5 4.81582 17.5 4.28104 17.2275C3.81063 16.9878 3.42818 16.6054 3.1885 16.135C2.91602 15.6002 2.91602 14.9001 2.91602 13.5V6.66667M2.99935 2.5H16.9993C17.4661 2.5 17.6994 2.5 17.8777 2.59083C18.0345 2.67072 18.162 2.79821 18.2419 2.95501C18.3327 3.13327 18.3327 3.36662 18.3327 3.83333V5.33333C18.3327 5.80004 18.3327 6.0334 18.2419 6.21166C18.162 6.36846 18.0345 6.49594 17.8777 6.57584C17.6994 6.66667 17.4661 6.66667 16.9994 6.66667H2.99935C2.53264 6.66667 2.29928 6.66667 2.12102 6.57584C1.96422 6.49594 1.83674 6.36846 1.75684 6.21166C1.66602 6.0334 1.66602 5.80004 1.66602 5.33333V3.83333C1.66602 3.36662 1.66602 3.13327 1.75684 2.95501C1.83674 2.79821 1.96422 2.67072 2.12102 2.59083C2.29928 2.5 2.53264 2.5 2.99935 2.5ZM7.99935 9.58333H11.9993C12.4661 9.58333 12.6994 9.58333 12.8777 9.67416C13.0345 9.75406 13.162 9.88154 13.2419 10.0383C13.3327 10.2166 13.3327 10.45 13.3327 10.9167V11.5833C13.3327 12.05 13.3327 12.2834 13.2419 12.4617C13.162 12.6185 13.0345 12.7459 12.8777 12.8258C12.6994 12.9167 12.4661 12.9167 11.9993 12.9167H7.99935C7.53264 12.9167 7.29928 12.9167 7.12102 12.8258C6.96422 12.7459 6.83674 12.6185 6.75684 12.4617C6.66602 12.2834 6.66602 12.05 6.66602 11.5833V10.9167C6.66602 10.45 6.66602 10.2166 6.75684 10.0383C6.83674 9.88154 6.96422 9.75406 7.12102 9.67416C7.29928 9.58333 7.53264 9.58333 7.99935 9.58333Z'
        stroke='#333333'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
