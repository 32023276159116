import { Box, Checkbox, FormControlLabel, Stack, styled, Typography } from '@mui/material'
import { IndexFileProps } from '../../../types'
import { accentColor, fontSize, outlineColor } from '@/utils/themeConfigs/customTheme'

const File = styled(Box)`
  display: flex;
  padding: 15px 0;
  word-break: break-all;
`

const FileIcon = styled(Typography)`
  width: 20px;
  height: 20px;
`

const Filename = styled(Typography)`
  font-size: ${fontSize.xs};
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const CustomLabel = styled(FormControlLabel)`
  margin: 0;
`
const CustomCheckbox = styled(Checkbox)`
  color: ${outlineColor.mediumGray};
  &.Mui-checked,
  &.MuiCheckbox-indeterminate {
    color: ${accentColor.primaryRed};
  }
`

export const IndexFile = ({ id, filename, checked, toggleChecked }: IndexFileProps) => {
  const handleChecked = () => {
    toggleChecked(id)
  }
  return (
    <Stack direction='row' justifyContent='space-between'>
      <File>
        <FileIcon>・</FileIcon>
        <Filename>{filename}</Filename>
      </File>
      <CustomLabel
        label=''
        control={
          <CustomCheckbox
            onClick={(e) => e.stopPropagation()} // expandedへの伝播を止める
            checked={checked}
            onChange={handleChecked}
          />
        }
      />
    </Stack>
  )
}
