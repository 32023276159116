import { MessageTextIcon } from '@/components/icon/MessageTextIcon'
import { UserIcon } from '@/components/icon/UserIcon'
import { PolicyIcon } from '@/components/icon/PolicyIcon'
import { DataflowIcon } from '@/components/icon/DataflowIcon'
import { FeedbackIcon } from '@/components/icon/FeedbackIcon'
import { PromptListIcon } from '@/components/icon/PromptListIcon'

export const ADMIN_MENU_ITEMS = [
  { id: 1, text: 'ユーザー一覧', icon: <UserIcon />, href: '/admin/users/students' },
  {
    id: 2,
    text: 'チャット履歴一覧',
    icon: <MessageTextIcon />,
    href: '/admin/chat-rooms',
  },
  {
    id: 6,
    text: 'プロンプト一覧',
    icon: <PromptListIcon />,
    href: '/admin/prompts',
  },
  {
    id: 3,
    text: 'グループ管理',
    icon: <DataflowIcon />,
    href: '/admin/group',
  },
  {
    id: 4,
    text: 'フィードバック一覧',
    icon: <FeedbackIcon />,
    href: '/admin/feedback',
  },
  {
    id: 5,
    text: 'ポリシー違反一覧',
    icon: <PolicyIcon />,
    href: '/admin/policy',
  },
]
