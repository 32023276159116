import { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router' // shallowがnext/navigationにない

import { Chat } from '@/components/layout/Chat/ChatBubble'
import { ChatHistoryType } from '@/components/layout/Chat/ChatHistory'
import { MutationKeys } from '@/utils/apis/api-keys'
import { baseURL } from '@/utils/apis/client'
import { useChatEndpoint } from '@/utils/hooks/useChatEndpoint'
import { API } from '@/utils/apis/endpoints'
import { useSnackbarContext } from '@/context/SnackbarContext'

import { useCreateImage } from './useCreateImage'
import { ChatContentType, ChatLogType, TextChatContentType } from './useFetchChatRoom'
import {
  useIndexStateContext,
  useSetIndexStateContext,
} from '@/context/IndexStateContext'

type SendChatArgumentType = {
  token: string
  model_id: number
  model_group: number
  chat_room_id?: string
  original_prompt_id?: string
  file_list?: string[]
  message?: string
  isVoiceInput?: boolean
  handleReadText?: (text: string) => void
}

type HandleTextChatArgumentType = {
  token: string
  model_id: number
  message: string
  endpoint: string
  chat_room_id?: string
  original_prompt_id?: string
  file_list?: string[]
  isVoiceInput?: boolean
  handleReadText?: (text: string) => void
}

type HandleImageChat = {
  token: string
  message: string
  model_id: number
  chatRoomId?: string
}

type PushChatHistoryArgumentType = {
  chat: Chat
}

type AppendTextToLastChatHistory = {
  text: string
}

type AppendChatLogIdToLastChatHistory = {
  chatLogId: string
}

type AppendFilenameToLastChatHistory = {
  fileName: string
}

type SseJsonType = {
  content: string
  type: string
}

type ChatErrorResponse = {
  detail: string
}

const isTextChat = (chat: ChatContentType): chat is TextChatContentType => {
  return typeof chat.content === 'string'
}

const getFileNameFromUrl = (url: string) => {
  return url.split('/').at(-1) ?? 'image.jpg'
}

export const useChatLogic = () => {
  const { checkedIndex, checkedFolder } = useIndexStateContext()
  const { setCheckedIndex, setCheckedFolder } = useSetIndexStateContext()
  const [prompt, setPrompt] = useState<{ id: string; title: string }>()
  const [chatHistory, setChatHistory] = useState<ChatHistoryType>([])
  const [chatInputValue, setChatInputValue] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)
  const { getEndpoint } = useChatEndpoint()

  const router = useRouter()
  const maxLength = 3000

  const [isAnswering, setIsAnswering] = useState(false)

  const queryClient = useQueryClient()

  const createImage = useCreateImage()

  const { showSnackbar } = useSnackbarContext()

  const pushChatHistory = ({ chat }: PushChatHistoryArgumentType) => {
    setChatHistory((prevChatHistory) => [...prevChatHistory, chat])
  }

  const handleChangeChatInput = useCallback((value: string) => {
    setChatInputValue(value)
    if (value.length > maxLength) setIsValid(false)
    else setIsValid(true)
  }, [])

  const appendTextToLastChatHistory = ({ text }: AppendTextToLastChatHistory) => {
    setChatHistory((prevChatHistory) => {
      const lastIndex = prevChatHistory.length - 1
      return prevChatHistory.map((chat, i) => {
        if (lastIndex !== i) return chat

        return {
          role: chat.role,
          message: chat.message + text,
        }
      })
    })
  }

  const appendChatLogIdToLastChatHistory = ({
    chatLogId,
  }: AppendChatLogIdToLastChatHistory) => {
    setChatHistory((prevChatHistory) => {
      const lastIndex = prevChatHistory.length - 1
      return prevChatHistory.map((chat, i) => {
        if (lastIndex !== i) return chat

        return {
          chatLogId: chatLogId,
          role: chat.role,
          message: chat.message,
        }
      })
    })
  }

  const appendFilenameToLastChatHistory = ({
    fileName,
  }: AppendFilenameToLastChatHistory) => {
    setChatHistory((prev) => {
      const lastIndex = prev.length - 1
      return prev.map((chat, i) => {
        if (lastIndex !== i) return chat

        return {
          chatLogId: chat.chatLogId,
          role: chat.role,
          message: chat.message,
          fileName: fileName,
        }
      })
    })
  }

  const handleTextChat = async ({
    token,
    model_id,
    message,
    endpoint,
    chat_room_id,
    file_list,
    original_prompt_id,
    isVoiceInput,
    handleReadText,
  }: HandleTextChatArgumentType) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    const formData = new FormData()
    formData.append('llm_model_id', String(model_id))
    formData.append('question', message)
    if (chat_room_id) {
      formData.append('chat_room_id_request', chat_room_id)
    }

    if (original_prompt_id) {
      formData.append('original_prompt_id', original_prompt_id)
    }
    if (file_list) {
      file_list.forEach((file) => formData.append(`file_ids`, file))
    }

    // SSEをPOSTで扱うにはfetchしか選択肢がないのでfetch利用
    // TODO: エラーハンドリングは後ほど実装予定
    const response = await fetch(baseURL + endpoint, {
      method: 'POST',
      headers,
      body: formData,
    })

    if (response.status !== 200) {
      try {
        const text = await response.text()
        const json = JSON.parse(text) as ChatErrorResponse
        showSnackbar?.({ text: json.detail, severity: 'error' })
      } catch {
        showSnackbar?.({
          text: 'エラーが発生しました。時間をおいて再度お試しください。',
          severity: 'error',
        })
      }
      return false
    }

    const reader = response.body?.getReader()

    if (!reader) {
      showSnackbar?.({
        text: 'エラーが発生しました。時間をおいて再度お試しください。',
        severity: 'error',
      })
      return false
    }

    // AIの回答を入れるようのチャットを末尾に追加
    pushChatHistory({ chat: { role: 'assistant', message: '' } })

    const decoder = new TextDecoder('utf-8')

    // APIからSSEでデータを受信し、随時更新する
    const read = async () => {
      const { done, value } = await reader.read()

      if (done) return true

      const decodedValue = decoder.decode(value)

      const lines = decodedValue
        .split('data: ')
        .map((line) => line.trim())
        .filter((s) => s)

      for (const line of lines) {
        // JSON.parseできない文字列が来た時の対策 :pingが来ることある
        try {
          const json = JSON.parse(line) as SseJsonType

          if (json.type === 'answer') {
            appendTextToLastChatHistory({ text: json.content })
            if (isVoiceInput && handleReadText) handleReadText(json.content)
          }

          if (json.type === 'error') {
            pushChatHistory({
              chat: {
                role: 'assistant',
                message: json.content,
              },
            })
          }

          // メッセージIDをURLパラメータに入れる
          if (json.type === 'chat_room_id') {
            await router.push('', `/${json.content}`, { shallow: true })
          }
          // chat_log_id
          if (json.type === 'assistant_log_id') {
            appendChatLogIdToLastChatHistory({ chatLogId: json.content })
          }
          // チャット生成に使用したファイル名
          if (json.type === 'index name') {
            appendFilenameToLastChatHistory({ fileName: json.content })
          }
        } catch (error) {
          if (!line.includes(': ping -')) console.error(error)
        }
      }

      await read()
    }
    await read()
  }

  const handleImageChat = ({ token, message, chatRoomId, model_id }: HandleImageChat) => {
    createImage.mutate(
      {
        token,
        chatRoomId,
        model_id,
        question: message,
      },
      {
        onSuccess: ({ image, text, type }) => {
          if (type === 'answer') {
            const name = getFileNameFromUrl(image)

            pushChatHistory({
              chat: {
                role: 'assistant',
                message: '生成された画像',
                image: {
                  src: image,
                  name,
                  text,
                },
              },
            })
          } else {
            pushChatHistory({
              chat: {
                role: 'assistant',
                message: text,
              },
            })
          }
        },
        onSettled: () => {
          setIsAnswering(false)
        },
      },
    )
  }

  const sendChat = async ({
    token,
    model_id,
    model_group,
    chat_room_id,
    original_prompt_id = prompt?.id,
    file_list = checkedIndex,
    message = chatInputValue.trim(),
    isVoiceInput,
    handleReadText,
  }: SendChatArgumentType) => {
    // 回答生成中に実行されたら処理を中断する
    if (isAnswering) return false

    setIsAnswering(true)

    // テキストが入力されていなかったら処理を止める
    if (!message) return false

    // バリデーションを満たしていなかったら処理を中断する
    if (!isValid) return false

    // ユーザのチャットを末尾に追加
    pushChatHistory({ chat: { role: 'user', message } })

    // IndexChatは同一endpoint
    const endpoint = file_list?.length > 0 ? API.chatIndex : getEndpoint(model_group)

    // TODO: エラーハンドリング
    if (!endpoint) return false

    setChatInputValue('')
    setIsValid(true)

    // 画像とチャットの切り替え
    if (model_group === 90) {
      // 画像
      handleImageChat({
        token,
        message,
        model_id,
        chatRoomId: chat_room_id,
      })
    } else {
      // チャット
      await handleTextChat({
        token,
        model_id,
        message,
        endpoint,
        chat_room_id,
        file_list,
        original_prompt_id,
        isVoiceInput,
        handleReadText,
      })
      setIsAnswering(false)

      await queryClient.invalidateQueries({ queryKey: MutationKeys.history })
    }
  }

  const syncChatHistory = useCallback((chatLog: ChatLogType) => {
    // 今表示されているチャットを初期化する
    setChatHistory([])

    if (chatLog[0].initial_message) {
      setChatHistory((prev) => [
        ...prev,
        {
          chatLogId: 'initial_message',
          feedbackId: 'initial_message',
          role: 'assistant',
          message: chatLog[0].initial_message,
        } as Chat,
      ])
    }

    // 外部から引数でチャットログをもらう
    // chatHistoryに値をセットする
    chatLog[1].map((chat) => {
      if (isTextChat(chat)) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            chatLogId: chat.chat_log_id,
            feedbackId: chat.feedback_id,
            role: chat.role,
            message: chat.content,
            fileName: chat.file_name,
          } as Chat,
        ])
      } else {
        const name = getFileNameFromUrl(chat.content.image[0])
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            role: chat.role === 'assistant_with_image' ? 'assistant' : 'user',
            message: '生成された画像',
            image: {
              src: chat.content.image[0],
              text: chat.content.text,
              name,
            },
          } as Chat,
        ])
      }
    })
  }, [])

  const initializeChatHistory = (value?: ChatHistoryType) => {
    setChatHistory(value ?? [])
  }

  const initializeChatInput = (value?: string) => {
    setChatInputValue(value ?? '')
  }

  const initializeCheckedIndex = () => {
    setCheckedIndex([])
    setCheckedFolder([])
  }

  return {
    prompt,
    setPrompt,
    checkedIndex,
    setCheckedIndex,
    checkedFolder,
    setCheckedFolder,
    chatInputValue,
    setChatInputValue,
    handleChangeChatInput,
    isValid,
    chatHistory,
    sendChat,
    isAnswering,
    syncChatHistory,
    initializeChatHistory,
    initializeChatInput,
    initializeCheckedIndex,
  }
}
