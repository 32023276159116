import { useEffect, useRef, useState } from 'react'

export const useSpeechSynthesis = () => {
  const speechSynthesis = useRef<SpeechSynthesis>()
  const readText = useRef('')
  const onend = useRef<() => void | undefined>()
  const [isSpeaking, setIsSpeaking] = useState(false)

  const isEnglish = () => {
    return !readText.current.split('').some((s) => s.charCodeAt(0) > 255)
  }

  const getVoices = () => {
    if (speechSynthesis?.current) {
      return speechSynthesis.current?.getVoices()
    }
  }

  const handleReadText = (text: string) => {
    readText.current += text
    if (!speechSynthesis.current?.speaking) {
      start()
    }
  }

  const start = () => {
    if (speechSynthesis.current) {
      // 文頭に記号が来ると読み上げてしまうため正規表現で除外
      // TODO: 読み上げたいケースが出てくる可能性の考慮が必要
      const reg = new RegExp(/^[ -/:-@[-`{-~！-／：-＠［-｀｛-～、-〜”’・]/)
      // URLを除外する
      const regUrl = new RegExp(
        /(https?|ftp):\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3001-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g,
      )
      const text = readText.current.replace(reg, '').replaceAll(regUrl, '')

      const utt = new SpeechSynthesisUtterance(text)
      utt.onend = () => {
        // 読まれていない後続文章があれば続きから再開させる
        if (readText.current) {
          start()
        } else {
          if (onend.current) onend.current()
          onend.current = undefined
          setIsSpeaking(false)
        }
      }

      const lang = isEnglish() ? 'en-US' : 'ja-JP'
      const voice = getVoices()?.filter((f) => f.lang === lang)
      if (voice) utt.voice = voice[0]

      setIsSpeaking(true)
      speechSynthesis.current.speak(utt)
      readText.current = ''
    }
  }

  const cancel = () => {
    if (speechSynthesis.current) {
      readText.current = ''
      speechSynthesis.current.cancel()
      setIsSpeaking(false)
    }
  }

  // iosの読み上げ命令無視対策：初回一度動かしておく
  const initialize = () => {
    const utt = new SpeechSynthesisUtterance('')
    utt.volume = 0
    speechSynthesis.current?.speak(utt)
    document.removeEventListener('touchend', initialize)
  }

  useEffect(() => {
    if (!speechSynthesis.current) {
      speechSynthesis.current = window.speechSynthesis
      document.addEventListener('touchend', initialize)
    }
    return () => {
      if (speechSynthesis.current) {
        cancel()
      }
    }
  }, [])

  return { cancel, isSpeaking, handleReadText, onend }
}
