import { BasicButton } from '@/components/uis/Button/BasicButton'
import { BasicModal } from '@/components/uis/Modal/BasicModal'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

const ModalFooter = styled(Box)`
  display: flex;
  gap: 16px;
  padding: 16px 0 0 0;
  justify-content: center;
`

const ModalText = styled(Typography)`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.448;
`

type Props = {
  open: boolean
  onClose: () => void
}

export const DenyLlmModalChangeModal = ({ open, onClose }: Props) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      wrapperWidth='ml'
      innerWidth='ml'
      title='ソースを用いた画像生成は利用できません。'
    >
      <>
        <ModalText>
          ソースを利用した画像生成はできません。 <br />
          画像生成以外を選択または、ソース選択を全て解除した上で再度画像生成を選択してください。
        </ModalText>
        <ModalFooter>
          <BasicButton
            label='閉じる'
            variant='outlined'
            width='140px'
            onClick={onClose}
          />
        </ModalFooter>
      </>
    </BasicModal>
  )
}
