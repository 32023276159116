import { useForm } from 'react-hook-form'
import { ChangeEvent, useCallback, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { BoxNameSchema } from '@/utils/helpers/schema/prompt'
import { useFetchBoxes, useBox } from './useBox'

export const useBoxes = (token: string) => {
  // prompt
  const [selectedId, setSelectedId] = useState('')
  // box
  const [selectedBoxes, setSelectedBoxes] = useState<string[]>([])
  const handleChangeCheckState = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value
    if (value && selectedBoxes.includes(value)) {
      setSelectedBoxes([...selectedBoxes.filter((f) => f !== value)])
    } else if (value && !selectedBoxes.includes(value)) {
      setSelectedBoxes([...selectedBoxes, value])
    }
  }
  // 検索ワード
  const [searchWord, setSearchWord] = useState('')
  const handleSetSearchWord = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchWord(e.target.value)
  }

  // ボックス新規作成
  const { control, watch, reset, handleSubmit } = useForm<{ boxName: string }>({
    resolver: zodResolver(BoxNameSchema),
    defaultValues: { boxName: '' },
  })

  const { data: boxes, refetch } = useFetchBoxes(token, searchWord)
  const { createBox, deleteBox, changeBox } = useBox()

  // handler
  const handleSearch = useCallback(() => {
    void (async () => {
      await refetch()
    })()
  }, [refetch])

  const handleCreateBox = () => {
    createBox(
      { token, boxName: watch('boxName') },
      {
        onSuccess: () => {
          reset()
        },
      },
    )
  }

  const handleDeleteBox = () => {
    deleteBox({ token, id: selectedId })
  }

  return {
    selectedId,
    setSelectedId,
    selectedBoxes,
    setSelectedBoxes,
    handleChangeCheckState,
    searchWord,
    handleSetSearchWord,
    control,
    hasValue: !!watch('boxName'),
    handleSubmit,
    boxes,
    handleDeleteBox,
    changeBox,
    handleSearch,
    handleCreateBox,
  }
}
