import { useState } from 'react'
import { usePathname } from 'next/navigation'

import { useAuthContext } from '@/context/AuthContext'
import { SideMenuItemWithDelete } from '@/components/layout/SideMenu/SideMenuItemWithDelete'
import { BasicAccordion } from '@/components/uis/Accordion/BasicAccordion'

import { DeleteModal } from './DeleteModal'
import { useFetchHistories } from '../../../hooks/useFetchHistories'
import { useDeleteHistory } from '../../../hooks/useDeleteHistory'

type Props = {
  expandedType: string
  expanded: string | false
  handleChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void
}

export const History = ({ expandedType, expanded, handleChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  const { user } = useAuthContext()
  const token = user?.token ?? ''

  const { data: histories } = useFetchHistories(token)

  const deleteHistory = useDeleteHistory({ token })

  const pathName = usePathname()

  const handleModalOpen = (id: string) => {
    setSelectedId(id)
    setIsOpen(true)
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  const handleModalSubmit = () => {
    deleteHistory.mutate({ chatRoomId: selectedId })
    setIsOpen(false)
    setSelectedId('')
  }

  return (
    <>
      <BasicAccordion
        title='履歴'
        expandedType={expandedType}
        expanded={expanded}
        handleChange={handleChange}
      >
        {histories &&
          histories.map((history) => (
            <SideMenuItemWithDelete
              key={history.id}
              id={history.id}
              text={history.chat_title}
              href={`/${history.id}`}
              modalOpen={handleModalOpen}
              isCurrent={pathName.includes(history.id)}
            />
          ))}
      </BasicAccordion>
      <DeleteModal open={isOpen} onClose={handleModalClose} submit={handleModalSubmit} />
    </>
  )
}
