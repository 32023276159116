import { FavoritePromptICon } from '@/components/icon/FavoritePromptIcon'
import { MyPromptIcon } from '@/components/icon/MyPromptsIcon'
import { PromptsIcon } from '@/components/icon/PromptsIcon'
import { textColor } from '../themeConfigs/customTheme'

export const PROMPT_MENU_ITEMS = [
  {
    id: 1,
    text: '共有プロンプト',
    icon: (isCurrent?: boolean) => (
      <PromptsIcon strokeColor={isCurrent ? textColor.red : undefined} />
    ),
    href: '/prompts',
  },
  {
    id: 2,
    text: 'マイプロンプト',
    icon: (isCurrent?: boolean) => (
      <MyPromptIcon strokeColor={isCurrent ? textColor.red : undefined} />
    ),
    href: '/prompts/myPrompt',
  },
  {
    id: 3,
    text: 'お気に入り',
    icon: (isCurrent?: boolean) => (
      <FavoritePromptICon strokeColor={isCurrent ? textColor.red : undefined} />
    ),
    href: '/prompts/favorite',
  },
]
