import { useMutation } from '@tanstack/react-query'
import { MutationKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'

export const useFetchTxt = () => {
  const { apiClient } = useApiClient()

  return useMutation({
    mutationKey: MutationKeys.chatTxt,
    mutationFn: async ({ token, chatRoomId }: { token: string; chatRoomId: string }) =>
      await apiClient(token).get<Blob>(API.chatTxt(chatRoomId), {
        responseType: 'blob',
      }),
    onSuccess: (res) => res,
  })
}

export const useTxtDownload = () => {
  const create = (data: Blob) => {
    return new Blob([data], { type: 'text/plain' })
  }

  const download = (blob: Blob, filename: string) => {
    const link = document.createElement('a')
    link.download = filename
    link.href = URL.createObjectURL(blob)
    link.click()

    URL.revokeObjectURL(link.href)
  }

  const createAndDownload = (data: Blob, filename: string) => {
    const blob = create(data)
    download(blob, filename)
  }

  return { create, download, createAndDownload }
}
