export const DataflowIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M10 3.33268V12.666C10 14.0661 10 14.7662 10.2725 15.301C10.5122 15.7714 10.8946 16.1538 11.365 16.3935C11.8998 16.666 12.5999 16.666 14 16.666H14.1667M14.1667 16.666C14.1667 17.5865 14.9129 18.3327 15.8333 18.3327C16.7538 18.3327 17.5 17.5865 17.5 16.666C17.5 15.7455 16.7538 14.9993 15.8333 14.9993C14.9129 14.9993 14.1667 15.7455 14.1667 16.666ZM5.83333 3.33268L14.1667 3.33268M5.83333 3.33268C5.83333 4.25316 5.08714 4.99935 4.16667 4.99935C3.24619 4.99935 2.5 4.25316 2.5 3.33268C2.5 2.41221 3.24619 1.66602 4.16667 1.66602C5.08714 1.66602 5.83333 2.41221 5.83333 3.33268ZM14.1667 3.33268C14.1667 4.25316 14.9129 4.99935 15.8333 4.99935C16.7538 4.99935 17.5 4.25316 17.5 3.33268C17.5 2.41221 16.7538 1.66602 15.8333 1.66602C14.9129 1.66602 14.1667 2.41221 14.1667 3.33268ZM10 9.99935H14.1667M14.1667 9.99935C14.1667 10.9198 14.9129 11.666 15.8333 11.666C16.7538 11.666 17.5 10.9198 17.5 9.99935C17.5 9.07887 16.7538 8.33268 15.8333 8.33268C14.9129 8.33268 14.1667 9.07887 14.1667 9.99935Z'
        stroke='#304386'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
