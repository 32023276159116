import { ChangeEvent } from 'react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

import { BasicButton } from '@/components/uis/Button/BasicButton'
import { BasicInputSearchText } from '@/components/uis/Input/BasicInputSearchText'

const Container = styled(Box)`
  display: flex;
  gap: 8px;
  padding: 12px 24px;
`

type BoxSearchProps = {
  placeholder: string
  buttonLabel: string
  value: string
  handleSetValue: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onClick: () => void
}

export const BoxSearch = ({
  placeholder,
  buttonLabel,
  value,
  handleSetValue,
  onClick,
}: BoxSearchProps) => {
  return (
    <Container>
      <BasicInputSearchText
        value={value}
        onChange={handleSetValue}
        isSearchItem={true}
        placeholder={placeholder}
        autoComplete='off'
      />
      <BasicButton
        label={buttonLabel}
        radius='10'
        width='56px'
        height='40px'
        onClick={onClick}
      />
    </Container>
  )
}
