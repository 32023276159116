import { User as FirebaseUser, OAuthCredential } from 'firebase/auth'
import { usePathname, useRouter } from 'next/navigation'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'

import { User } from '@/types/user'
import { Info, Me, useGetMe } from '@/utils/hooks/useAuth'
import { auth } from '@/utils/libs/firebase/client'
import { useAuthentication } from '@/utils/hooks/useAuthentication'
import { Loading } from '@/components/uis/Loading/Loading'

import { useInfoContext, useSetInfoContext } from './InfoConrtext'

import { useRouter as useNextRouter } from 'next/router'
type AuthProviderProps = {
  children: React.ReactNode
}

type AuthContextProps = {
  user: User | null
  isStudent: boolean
  isAdmin: boolean
  controller: AbortController | null
}

type SetAuthContextProps = {
  setUser: (user: User | null) => void
  setIsStudent: (val: boolean) => void
  setIsAdmin: (val: boolean) => void
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  isStudent: true,
  isAdmin: false,
  controller: null,
})

const SetAuthContext = createContext<SetAuthContextProps>({
  setUser: () => {},
  setIsStudent: () => {},
  setIsAdmin: () => {},
})

export const useAuthContext = () => useContext(AuthContext)

export const useSetAuthContext = () => useContext(SetAuthContext)

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [isStudent, setIsStudent] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { logout, validToken, validExp, equalTokenExp, needsIdTokenRefresh } =
    useAuthentication()
  const { mutateAsync } = useGetMe()
  const { modal } = useInfoContext()
  const { setInfoSummary } = useSetInfoContext()

  const router = useRouter()
  const pathName = usePathname()
  const nextRouter = useNextRouter()

  const controller = new AbortController()

  const initializeUser = useCallback(
    (url: string) => {
      const index = url.indexOf('?')
      const next = url.substring(0, index)
      if (next ? next !== pathName : url !== pathName) {
        setUser(null)
      }
    },
    [pathName],
  )

  const protectRoute = (isAdminUser: boolean) => {
    // 管理者権限制御
    if (pathName?.includes('admin') && !isAdminUser) {
      router.replace('/')
    } else {
      // protectにヒットしなかった場合のみ通過して画面描画する
      setIsLoading(false)
    }
  }

  const updateToken = async (
    user: FirebaseUser | null,
    token?: string,
    refreshToken?: string,
  ) => {
    if (token && validToken(token)) {
      // 有効期限内ならtokenをリフレッシュする
      // localStorageのexpも更新
      const newToken = await user?.getIdToken(true)
      const data = jwt.decode(newToken ?? token) as jwt.JwtPayload
      const newExp = data.exp
      localStorage.setItem('AUTH-EXP', newExp?.toString() ?? '0')

      const response = await mutateAsync(newToken ?? token)
      if (!response || typeof response[0] !== 'object') return

      setUser({
        token: newToken ?? token,
        refreshToken: refreshToken ?? '',
        id: (response[0] as Me)?.id,
        roleId: typeof response[0] === 'object' ? (response[0] as Me).role_id : -1,
        email: typeof response[0] === 'object' ? (response[0] as Me).email : '',
        infoFlag: typeof response[0] === 'object' ? (response[0] as Me).info_flag : false,
      })
      setIsStudent((response[0] as Me).role_id === 1)
      setIsAdmin((response[0] as Me).role_id === 3)

      // nullと空配列の場合はお知らせモーダルを出さない
      // お知らせがある場合はInfo型のObjectが格納されている
      if (response[1] !== null && !Array.isArray(response[1])) {
        setInfoSummary(response[1] as Info)
        const isUnread = localStorage.getItem('INFO-UNREAD')
        if ((!isUnread || isUnread === '0') && (response[0] as Me).info_flag)
          modal?.handleOpen()
      }
      return (response[0] as Me).role_id === 3
    } else if (token) {
      // tokenの期限切れ
      await logout()
    } else {
      // tokenの取得に失敗？
      await logout()
    }
  }

  // 遷移前にuser情報をnullにする
  // 遷移時にMeが最初に呼ばれるようになる
  useEffect(() => {
    nextRouter.events.on('routeChangeStart', initializeUser)
    return () => {
      nextRouter.events.off('routeChangeStart', initializeUser)
    }
  }, [pathName])

  useEffect(() => {
    const expStr = localStorage.getItem('AUTH-EXP')
    const exp = Number(expStr)

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const accessToken = (user as unknown as OAuthCredential).accessToken as string
        if (
          exp &&
          validExp(exp) &&
          (equalTokenExp(accessToken, exp) || needsIdTokenRefresh(exp))
        ) {
          if (pathName === '/login/') {
            router.push('/')
          } else {
            void (async () => {
              const isAdminUser = await updateToken(user, accessToken, user.refreshToken)
              protectRoute(!!isAdminUser)
            })()
          }
        } else {
          void (async () => {
            await logout()
          })()
        }
      } else {
        if (pathName !== '/login/') {
          void (async () => {
            await logout()
          })()
        }
      }
      unsubscribe()
    })
    // TODO: Warning：updateTokenは含めると無限ループが発生する
  }, [pathName, router, validExp, needsIdTokenRefresh, equalTokenExp])

  return (
    <AuthContext.Provider value={{ user, isAdmin, isStudent, controller }}>
      <SetAuthContext.Provider value={{ setUser, setIsStudent, setIsAdmin }}>
        {pathName !== '/login/' ? isLoading ? <Loading centered /> : children : children}
      </SetAuthContext.Provider>
    </AuthContext.Provider>
  )
}
