import styled from '@emotion/styled'
import { TextField, TextFieldProps } from '@mui/material'
import {
  functionalColor,
  textColor,
  fontSize,
  outlineColor,
  bgColor,
} from '@/utils/themeConfigs/customTheme'
import { BasicLabel } from '@/components/uis/Input/BasicLabel'

const Wrapper = styled.div<{ width?: string; maxWidth?: string }>`
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
`

const ErrorMessage = styled.div`
  color: ${functionalColor.red};
  font-size: ${fontSize.xxs};
  margin-top: 2px;
`

export const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'hasError' && prop !== 'customDisabledColor',
})<{
  textColor?: string
  width?: string
  hasError?: boolean
  disabled: boolean
  customDisabledColor?: string
}>`
  width: 100%;

  .MuiOutlinedInput-root {
    border: solid 1px
      ${({ hasError }) => (hasError ? functionalColor.red : outlineColor.lightGray)};
    border-radius: 6px;
    height: 40px;
    background-color: ${bgColor.white};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiInputBase-input {
    padding: 10px 12px;
    font-size: ${fontSize.sm};

    &::placeholder {
      color: ${textColor.lightGray};
      opacity: 1;
    }
  }

  ${({ customDisabledColor, disabled }) =>
    customDisabledColor && disabled
      ? `
        .Mui-disabled {
          color: ${customDisabledColor};
          -webkit-text-fill-color: ${customDisabledColor} !important;
        }
      `
      : disabled &&
        `
        fieldset {
          background-color: ${bgColor.lightestGray};
        }
      `}
`

type CustomTextFieldProps = Omit<TextFieldProps, 'id' | 'label' | 'onClick'>

type AdditionalTextFieldProps = {
  id?: string
  maxLength?: number
  type?: string
  errors?: boolean
  width?: string
  label?: string
  errorMessage?: string
  value?: string
  isRequired?: boolean
  disabled?: boolean
  customDisabledColor?: string
}

export type InputTextProps = CustomTextFieldProps & AdditionalTextFieldProps

export const BasicInputText = (props: InputTextProps) => {
  const {
    id,
    inputProps,
    maxLength = 255,
    type = 'text',
    errors,
    width,
    label,
    value,
    errorMessage,
    isRequired = false,
    disabled = false,
    customDisabledColor = undefined,
    ...customTextFieldProps
  } = props

  return (
    <Wrapper width={width}>
      {label && (
        <BasicLabel
          label={label}
          htmlFor={id}
          isRequired={isRequired}
          disabled={disabled && !customDisabledColor}
        />
      )}
      <CustomTextField
        id={id}
        type={type}
        value={value}
        hasError={!!errors}
        width={width}
        disabled={disabled}
        customDisabledColor={customDisabledColor}
        {...customTextFieldProps}
        inputProps={{
          maxLength,
          ...inputProps,
        }}
      />
      {errors && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  )
}
