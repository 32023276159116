import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

type Props = {
  children: React.ReactNode
}

type IndexStateContextProps = {
  checkedIndex: string[]
  checkedFolder: string[]
}

type SetIndexStateContextProps = {
  setCheckedIndex: Dispatch<SetStateAction<string[]>>
  setCheckedFolder: Dispatch<SetStateAction<string[]>>
}

const IndexStateContext = createContext<IndexStateContextProps>({
  checkedIndex: [],
  checkedFolder: [],
})

const SetIndexStateContext = createContext<SetIndexStateContextProps>({
  setCheckedIndex: () => {},
  setCheckedFolder: () => {},
})

export const useIndexStateContext = () => useContext(IndexStateContext)
export const useSetIndexStateContext = () => useContext(SetIndexStateContext)

export const IndexStateProvider = ({ children }: Props) => {
  const [checkedIndex, setCheckedIndex] = useState<string[]>([])
  const [checkedFolder, setCheckedFolder] = useState<string[]>([])

  return (
    <IndexStateContext.Provider value={{ checkedIndex, checkedFolder }}>
      <SetIndexStateContext.Provider value={{ setCheckedFolder, setCheckedIndex }}>
        {children}
      </SetIndexStateContext.Provider>
    </IndexStateContext.Provider>
  )
}
