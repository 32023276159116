import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { fontWeight, textColor } from '@/utils/themeConfigs/customTheme'
import { BasicInputSearchText } from '@/components/uis/Input/BasicInputSearchText'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { useExpanded } from '@/utils/hooks/useExpanded'
import { useExpandedContext } from '@/context/ExpandedContext'
import {
  useIndexStateContext,
  useSetIndexStateContext,
} from '@/context/IndexStateContext'
import { Loading } from '@/components/uis/Loading/Loading'

import { History } from '../History/History'
import { IndexAccordion } from './IndexAccordion'
import { SidebarProps } from '../../../types'
import { useFetchIndex } from '../../../hooks/useFetchIndex'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 0 0 40px;
  ${mediaQuery('tab')} {
    padding: 0 0 36px;
  }
`

const Head = styled(Box)`
  padding: 24px 0 0;
  border-bottom: 1px solid rgba(206, 206, 206, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
`
const TitleBox = styled(Box)`
  padding: 16px;
  display: flex;
  gap: 19px;
  flex-direction: column;
`

const Title = styled(Typography)`
  font-weight: ${fontWeight.bold};
  padding: 0 0 0 8px;
`

const Main = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 140px);
  overflow: hidden;
`

const Center = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 32px 0;
`

const Index = styled(Box, {
  shouldForwardProp: (props) => props !== 'isHistoriesOpen',
})<{ isHistoriesOpen: boolean }>`
  flex-grow: 1;
  overflow-y: scroll;
  ${mediaQuery('tab')} {
    ${({ isHistoriesOpen }) => isHistoriesOpen && 'height:0;'}
  }
`

const NoResult = styled(Box)`
  color: ${textColor.dark};
  padding: 24px;
`

const Histories = styled(Box, {
  shouldForwardProp: (props) => props !== 'isHistoriesOpen',
})<{ isHistoriesOpen: boolean }>`
  max-height: 50%;
  ${mediaQuery('tab')} {
    max-height: 100%;
    height: ${({ isHistoriesOpen }) => (isHistoriesOpen ? '100%' : 'fit-content')};
  }
`

export const Sidebar = ({ token }: SidebarProps) => {
  const indexExpanded = 'indexes'
  const historyExpanded = 'histories'
  const isMobile = useIsMobileOrTablet()

  // Index
  const { checkedIndex, checkedFolder } = useIndexStateContext()
  const { setCheckedIndex, setCheckedFolder } = useSetIndexStateContext()

  // history用
  const { expanded: expandedContext } = useExpandedContext()
  const { expanded, handleChange, forceClose } = useExpanded({
    init: expandedContext[historyExpanded],
  })

  // 検索
  const [searchText, setSearchText] = useState('')
  const [isComposition, setIsComposition] = useState(false)

  const handleChangeText = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target) setSearchText(e.target.value)
  }
  const handleSetIsComposition = () => {
    setIsComposition((prev) => (prev ? false : true))
  }
  const handleClear = useCallback(() => {
    setSearchText('')
  }, [])

  // IndexData
  const { data, isPending, isFetching } = useFetchIndex({
    token,
    searchWord: searchText,
    isComposition,
  })

  useEffect(() => {
    // SP版は検索文字列が変更されたらメニューを閉じる
    if (isMobile && expanded) {
      forceClose()
    }
  }, [searchText])

  useEffect(() => {
    expandedContext[historyExpanded] = expanded
  }, [expanded, expandedContext])

  return (
    <Container>
      <Head>
        <TitleBox>
          <Title>ソース選択</Title>
          <BasicInputSearchText
            value={searchText}
            onChange={handleChangeText}
            isSearchItem
            clearable={!!searchText}
            handleClear={handleClear}
            placeholder='検索'
            autoComplete='off'
            onCompositionStart={handleSetIsComposition}
            onCompositionEnd={handleSetIsComposition}
          />
        </TitleBox>
      </Head>
      <Main>
        <Index isHistoriesOpen={expanded === historyExpanded}>
          {!isPending && !isFetching ? (
            data && data.length > 0 ? (
              <IndexAccordion
                item={data ?? []}
                expandedType={indexExpanded}
                checkedIndex={checkedIndex}
                setCheckedIndex={setCheckedIndex}
                checkedFolder={checkedFolder}
                setCheckedFolder={setCheckedFolder}
              />
            ) : (
              <NoResult>検索結果がありません</NoResult>
            )
          ) : (
            <Center>
              <Loading />
            </Center>
          )}
        </Index>
        <Histories isHistoriesOpen={expanded === historyExpanded}>
          <History
            expandedType={historyExpanded}
            expanded={expanded}
            handleChange={handleChange}
          />
        </Histories>
      </Main>
    </Container>
  )
}
