import {
  useController,
  FieldValues,
  UseControllerProps,
  Controller,
} from 'react-hook-form'
import { BasicInputText, InputTextProps } from '@/components/uis/Input/BasicInputText'

type RhfInputTextProps<T extends FieldValues> = InputTextProps & UseControllerProps<T>

export const RhfInputText = <T extends FieldValues>(props: RhfInputTextProps<T>) => {
  const { name, control, customDisabledColor, ...rest } = props
  const {
    field: { ref, ...fieldProps },
    formState: { errors },
  } = useController<T>({ name, control })

  const errorMessage = errors[name]?.message as string

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <BasicInputText
          {...rest}
          {...fieldProps}
          inputRef={ref}
          errors={!!errors[name]}
          errorMessage={errorMessage}
          customDisabledColor={customDisabledColor}
        />
      )}
    />
  )
}
