import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'
import { useAuthContext } from '@/context/AuthContext'
import { PromptDetail } from '../types'

export const useGetPrompt = (token: string, id: string) => {
  const { apiClient } = useApiClient()
  const { controller } = useAuthContext()

  return useQuery({
    queryKey: QueryKeys.prompt,
    queryFn: () =>
      apiClient(token)
        .get<PromptDetail>(API.prompt(id), { signal: controller?.signal })
        .then((res) => res.data),
    enabled: !!token && !!id,
  })
}
