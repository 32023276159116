import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { mainColor, textColor } from '@/utils/themeConfigs/customTheme'
import { VariantTypeProps } from '@/utils/types/common'

const CustomMuiButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<{
  width: string
  height: string
  customColor?: string
}>`
  width: ${({ width }: { width: string }) => width};
  border: solid 1px ${mainColor.admin};
  border-radius: 20px;
  border: ${({ disabled, customColor }) =>
    !disabled && `solid 1px  ${customColor ?? mainColor.user}`};

  color: ${({ variant, customColor }) =>
    variant === 'outlined' ? `${customColor ?? mainColor.user}` : `${textColor.white}`};
  height: 40px;
  .MuiButton-startIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    color: ${({ variant, customColor }) =>
      variant === 'outlined' ? `${customColor ?? mainColor.user}` : `${textColor.white}`};
    box-shadow: none;
    border: solid 1px ${({ customColor }) => customColor ?? mainColor.user};
  }
`

type BasicButtonWithIconProps = {
  label: string
  icon: React.ReactElement
  variant?: VariantTypeProps
  width?: string
  height?: string
  type?: 'submit' | 'button'
  onClick?: () => void
  disabled?: boolean
  color?: string
}

export const BasicButtonWithIcon = ({
  label,
  icon,
  variant = 'outlined',
  width = 'auto',
  height = 'auto',
  type = 'button',
  onClick,
  disabled = false,
  color,
}: BasicButtonWithIconProps) => {
  return (
    <CustomMuiButton
      startIcon={icon}
      width={width}
      height={height}
      variant={variant}
      type={type}
      onClick={onClick}
      disabled={disabled}
      customColor={color}
    >
      {label}
    </CustomMuiButton>
  )
}
