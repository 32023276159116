import { useState } from 'react'
type Props = {
  init?: string | false
}
export const useExpanded = ({ init }: Props) => {
  const [expanded, setExpanded] = useState<string | false>(init ?? false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const forceClose = () => {
    setExpanded(false)
  }

  return {
    expanded,
    handleChange,
    forceClose,
  }
}
