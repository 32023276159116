import styled from '@emotion/styled'
import { Accordion, AccordionDetails, AccordionSummary, Collapse } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { fontSize, fontWeight, outlineColor } from '@/utils/themeConfigs/customTheme'

const CustomAccordion = styled(Accordion)`
  ${({ expanded }) => expanded && 'height:100%'};
  background-color: inherit;
  box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(206, 206, 206, 1);

  &.MuiAccordion-root {
    border-radius: 0;

    .MuiCollapse-root {
      ${({ expanded }) => expanded && 'height:100% !important'};
      .MuiCollapse-wrapper {
        height: 100%;

        .MuiCollapse-wrapperInner {
          .MuiAccordion-region {
            overflow-y: scroll;
            height: calc(100% - 50px);
            max-height: calc(100% - 50px);
          }
        }
      }
    }
  }

  &.Mui-expanded {
    border-top: 1px solid ${outlineColor.lightGray};
    margin: 0;
    box-shadow: 0px -2px 12px 0px #0000000d;
    border-radius: 0;
  }
`

const CustomAccordionSummary = styled(AccordionSummary)`
  flex-direction: row-reverse;
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.bold};

  &.Mui-expanded {
    height: 50px;
    min-height: 50px;
  }

  .MuiAccordionSummary-content {
    margin: 14px 0 !important;
  }
`

const Details = styled(AccordionDetails)`
  padding: 0;
`

type Props = {
  title: string
  expandedType: string
  expanded: string | false
  handleChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  children: React.ReactNode
}

export const BasicAccordion = ({
  title,
  expandedType,
  expanded,
  handleChange,
  children,
}: Props) => {
  return (
    <CustomAccordion
      expanded={expanded === expandedType}
      onChange={handleChange(expandedType)}
      slots={{ transition: Collapse }}
      slotProps={{ transition: { timeout: 300 } }}
    >
      <CustomAccordionSummary
        expandIcon={expanded === expandedType ? <RemoveIcon /> : <AddIcon />}
      >
        {title}
      </CustomAccordionSummary>
      <Details>{children}</Details>
    </CustomAccordion>
  )
}
