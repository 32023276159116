import styled from '@emotion/styled'
import { useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  accentColor,
  fontSize,
  fontWeight,
  outlineColor,
} from '@/utils/themeConfigs/customTheme'
import { useExpanded } from '@/utils/hooks/useExpanded'
import { useExpandedContext } from '@/context/ExpandedContext'

type Props = {
  id: string
  title: string
  expandedType: string
  checked: boolean
  indeterminate: boolean
  toggleChecked: (id: string) => void
  isChild?: boolean
  children: React.ReactNode
}

const CustomAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== 'isChild',
})<{ isChild?: boolean }>`
  background-color: inherit;
  padding: 0;
  box-shadow: none;

  ::before {
    background-color: inherit;
  }

  &.Mui-expanded {
    margin: 0;
  }

  .MuiCollapse-root {
    background-color: ${({ isChild }) =>
      isChild ? 'inherit' : 'rgba(255, 255, 255, 0.2)'};
  }
`

const CustomAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'isChild',
})<{ isChild?: boolean }>`
  flex-direction: row-reverse;
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.bold};
  height: 50px;
  min-height: 50px;
  padding: ${({ isChild }) => (isChild ? '0' : '0 24px')};

  &.Mui-expanded {
    height: 50px;
    min-height: 50px;
  }

  .MuiAccordionSummary-content {
    justify-content: space-between;
    align-items: center;
  }
`

const Details = styled(AccordionDetails, {
  shouldForwardProp: (prop) => prop !== 'isChild',
})<{ isChild?: boolean }>`
  padding: ${({ isChild }) => (isChild ? '0' : '0 24px 0 40px')};
  font-size: ${fontSize.xs};
`

const CustomLabel = styled(FormControlLabel)`
  margin: 0;
`

const CustomCheckbox = styled(Checkbox)`
  color: ${outlineColor.mediumGray};

  &.Mui-checked,
  &.MuiCheckbox-indeterminate {
    color: ${accentColor.primaryRed};
  }
`

export const BasicCheckBoxAccordion = ({
  id,
  expandedType,
  title,
  checked,
  toggleChecked,
  indeterminate,
  isChild,
  children,
}: Props) => {
  const { expanded: expandedContext } = useExpandedContext()
  const { expanded, handleChange } = useExpanded({ init: expandedContext[expandedType] })

  const handleChecked = () => {
    toggleChecked(id)
  }

  useEffect(() => {
    expandedContext[expandedType] = expanded
  }, [expanded])

  return (
    <CustomAccordion
      expanded={expanded === expandedType}
      onChange={handleChange(expandedType)}
      isChild={isChild}
    >
      <CustomAccordionSummary
        isChild={isChild}
        expandIcon={expanded === expandedType ? <RemoveIcon /> : <AddIcon />}
      >
        {title}
        <CustomLabel
          label=''
          control={
            <CustomCheckbox
              onClick={(e) => e.stopPropagation()} // expandedへの伝播を止める
              checked={checked}
              indeterminate={indeterminate}
              onChange={handleChecked}
            />
          }
        />
      </CustomAccordionSummary>
      <Details isChild={isChild}>{children}</Details>
    </CustomAccordion>
  )
}
