import { useState } from 'react'
import { usePathname } from 'next/navigation'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { SideMenuItemWithDelete } from '@/components/layout/SideMenu/SideMenuItemWithDelete'
import { fontSize, fontWeight, textColor } from '@/utils/themeConfigs/customTheme'
import { BoxIcon } from '@/components/icon/BoxIcon'
import { SideMenuItem } from '@/components/layout/SideMenu/SideMenuItem'

import { BoxDeleteModal } from '../Box/BoxDeleteModal'
import { BoxSearch } from './BoxSearch'
import { BoxesProps } from '../../../types'
import { useBoxes } from '../../../hooks/useBoxes'
import { BoxCreate } from './BoxCreate'

const Container = styled(Box)`
  padding: 0px 0px 24px;
  height: 100%;
  box-shadow: 0px 4px 12px 0px #0000000d;
`

const ScrollInner = styled(Box)`
  height: calc(100% - 266px); // プロンプト系ボタンとボックスタイトル分を考慮
  overflow: auto;
`

const TitleContainer = styled(Box)`
  display: flex;
  gap: 4px;
  padding: 15px 16px 15px 24px;
`

const Title = styled(Typography)`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.xs};
  color: ${textColor.dark};
`
const ItemList = styled(Box)``

export const Boxes = ({
  token,
  userId,
  isStudent = true,
  isAdmin = false,
}: BoxesProps) => {
  const pathName = usePathname()
  const [isOpen, setIsOpen] = useState(false)

  const {
    setSelectedId,
    searchWord,
    handleSetSearchWord,
    control,
    hasValue,
    handleSubmit,
    boxes,
    handleDeleteBox,
    handleSearch,
    handleCreateBox,
  } = useBoxes(token)

  const handleModalOpen = (id: string) => {
    setSelectedId(id)
    setIsOpen(true)
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  const handleModalSubmit = () => {
    handleDeleteBox()
    setIsOpen(false)
    setSelectedId('')
  }

  return (
    <>
      <Container>
        <TitleContainer>
          <BoxIcon />
          <Title>ボックス</Title>
        </TitleContainer>
        <ScrollInner>
          <BoxSearch
            placeholder='検索'
            buttonLabel='検索'
            value={searchWord}
            handleSetValue={handleSetSearchWord}
            onClick={handleSearch}
          />
          {!isStudent && (
            <BoxCreate
              control={control}
              hasValue={hasValue}
              handleSubmit={handleSubmit}
              handleCreateBox={handleCreateBox}
            />
          )}

          <ItemList>
            {!!boxes &&
              boxes?.map((item) =>
                isAdmin || item.user_id === userId ? (
                  <SideMenuItemWithDelete
                    key={item.box_id}
                    id={item.box_id}
                    text={item.name}
                    href={`/prompts/boxes/${item.box_id}`}
                    modalOpen={handleModalOpen}
                    isCurrent={pathName.includes(item.box_id)}
                  />
                ) : (
                  <SideMenuItem
                    key={item.box_id}
                    text={item.name}
                    href={`/prompts/boxes/${item.box_id}`}
                    isCurrent={pathName.includes(item.box_id)}
                  />
                ),
              )}
          </ItemList>
        </ScrollInner>
      </Container>
      <BoxDeleteModal
        open={isOpen}
        onClose={handleModalClose}
        submit={handleModalSubmit}
      />
    </>
  )
}
